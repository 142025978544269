import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import RollingBanner from './sections/RollingBanner'

const Footer = ({ data, isDark }) => {


	return (
		<section className={`Footer ${isDark === true || isDark === null ? 'Footer--IsDark' : ''}`}>
			{data.data.text &&
				<div className="Footer__Text"> <RichText render={data.data.text.richText}/> </div>
			}
			<ul className="Footer__Links">
				{data.data.links.map((link, index) => (
					<li className="Footer__Link" key={`footer-links-${index}`}>
						<a href={link.link.url} target="_blank">
							{link.label.text}
						</a>
						{index < data.data.links.length - 1 && <span> / </span> }
					</li>
				))}
			</ul>
			<div className="Footer__BannerContainer">
				<RollingBanner inlineSvg={data.data.inline_svg.text} duration={14} />
			</div>
		</section>
	)
}

export default Footer

export const query = graphql`
	fragment Footer on PrismicFooter {
		data {
			text {
        richText
      }
      links {
        label {
          text
        }
        link {
          url
        }
      }
      inline_svg {
        text
      }
		}
	}
`
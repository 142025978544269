import React, { useState, useEffect, useRef } from 'react'
import useOnScreen from '../hooks/useOnScreen';

const PreloadImage = ({ src, srcSet, alt, className, style }) => {

	const containerEl = useRef(null)
	const containerElIsOnScreen = useOnScreen(containerEl)

	const [imageSrc, setImageSrc] = useState(null)
	useEffect(() => {
		const img = new Image()
		img.onload = () => {
			setImageSrc(src)
		}
		img.src = src
	}, [src])

	const [isImageLoaded, setIsImageLoaded] = useState(false)
	useEffect(() => {
		if (imageSrc !== null) {
			setTimeout(() => {
				setIsImageLoaded(true)
			}, 500);
		}
	}, [imageSrc])

	return (
		<div
			className={`PreloadImage ${className} ${isImageLoaded && containerElIsOnScreen ? 'PreloadImage--OnScreenAndLoaded' : ''}`}
			style={style}
			ref={containerEl}
		>
			<img
				className="PreloadImage__Image"
				alt={alt}
				src={imageSrc}
				srcSet={srcSet}
			/>
		</div>
	)
}

export default PreloadImage
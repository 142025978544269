import React from 'react'
import { RichText } from 'prismic-reactjs'
import { cols } from '../../helpers/sizes'
import PreloadImage from '../PreloadImage.js'

const Dual = ({ data }) => {
	const mainData = data.primary

	const imageStyles = {
		width: cols(mainData.image_width_in_columns) + 'vw',
		marginLeft: cols(mainData.image_margin_left_in_columns) + 'vw',
	}
	if (mainData.image_vertical_alignment === 'top') {
		imageStyles.justifySelf = 'flex-start';
		imageStyles.marginBottom = 'auto';
	}
	else if (mainData.image_vertical_alignment === 'bottom') {
		imageStyles.justifySelf = 'flex-end';
		imageStyles.marginTop = 'auto';
	}
	else if (mainData.image_vertical_alignment === 'center') {
		imageStyles.justifySelf = 'center';
		imageStyles.marginTop = 'auto';
		imageStyles.marginBottom = 'auto';
	}

	return (
		<section
			className="Dual"
			style={{ flexDirection: mainData.big_image_side === 'right' ? 'row-reverse' : '' }}
		>
			<div
				className="Dual__BigImageSide"
				style={{ width: cols(mainData.big_image_side_width_in_columns || 6) + 'vw' }}
			>
				{mainData.big_image.fluid && mainData.big_video.url === null &&
					<PreloadImage
						className="Dual__BigImage"
						src={mainData.big_image.fluid.src}
						srcSet={mainData.big_image.fluid.srcSet}
						alt={mainData.big_image.alt}
					/>
				}
				{mainData.big_video.url !== null &&
					<video
						className="Dual__BigVideo"
						src={mainData.big_video.url}
						muted
						autoPlay={true}
						loop={true}
						playsInline={true}
					></video>
				}
			</div>
			<div
				className="Dual__OtherSide"
				style={{ width: cols(mainData.other_side_width_in_columns || 6) + 'vw' }}
			>
				{mainData.paragraph_1_content.raw.length > 0 &&
					<div
						className="Dual__Paragraph1"
						style={{
							width: cols(mainData.paragraph_1_width_in_columns) + 'vw',
							marginLeft: cols(mainData.paragraph_1_margin_left_in_columns) + 'vw',
						}}
					>
						<RichText render={mainData.paragraph_1_content.raw} />
					</div>
				}
				{mainData.image.fluid  && mainData.big_video.url === null &&
					<div
						className="Dual__ImageContainer"
						style={imageStyles}
					>
						<PreloadImage
							className="Dual__Image"
							src={mainData.image.fluid.src}
							srcSet={mainData.image.fluid.srcSet}
							alt={mainData.image.alt}
						/>
					</div>
				}
				{mainData.video.url !== null &&
					<div
							className="Dual__ImageContainer"
							style={imageStyles}
						>
						<video
							className="Dual__Video"
							src={mainData.video.url}
							muted
							autoPlay={true}
							loop={true}
							playsInline={true}
						></video>
					</div>
				}
				{mainData.paragraph_2_content.raw.length > 0 &&
					<div
						className="Dual__Paragraph2"
						style={{
							width: cols(mainData.paragraph_2_width_in_columns) + 'vw',
							marginLeft: cols(mainData.paragraph_2_margin_left_in_columns) + 'vw',
						}}
					>
						<RichText render={mainData.paragraph_2_content.raw} />
					</div>
				}
			</div>
		</section>
	)
}

export default Dual
import React, { useEffect, useRef, useContext } from 'react'
import { graphql, navigate } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import Helmet from 'react-helmet'
import { ContextProjectIndex } from '../context'
import * as ComponentsSections from '../components/sections'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import { MOBILE_BP, ratio, ratioM } from '../helpers/sizes'
import { GlobalEventBus } from '../helpers/eventBus'

const Project = ({ pageContext, data }) => {

	const [projectIndex, updateProjectIndex] = useContext(ContextProjectIndex)
	const pageData = data.prismicProject.data
	const slices = pageData.body
	const containerEl = useRef(null)
	const headerEl = useRef(null)

	useEffect(() => {
		const projectData = data.prismicProject.data
		document.documentElement.style.setProperty('--colorLogo', projectData.color_project_page_logo)
		document.documentElement.style.setProperty('--colorProjectPageHeaderBackground', projectData.color_project_page_header_background)
		document.documentElement.style.setProperty('--colorProjectPageHeaderText', projectData.color_project_page_header_text)
		document.documentElement.style.setProperty('--colorProjectPageBodyText', projectData.color_project_page_body_text)
		document.documentElement.style.setProperty('--colorProjectPageHighlight', projectData.color_project_page_highlight)
		document.documentElement.style.setProperty('--colorBackground', projectData.color_project_page_background)
		document.documentElement.style.setProperty('--colorTransitionBackground', projectData.color_transition_background)
	}, [])

	// Update projectIndex
	useEffect(() => {
		const newProjectIndex = data.prismicHomepage.data.projects.findIndex(p => p.project.document.uid === data.prismicProject.uid)
		// Check if index is defined because project are not necessarily in the home projects list
		if (newProjectIndex !== -1) {
			updateProjectIndex(newProjectIndex)
		}
	}, [])

	// Header margin bottom
	useEffect(() => {
		let marginBottomInVw = null
		if (localStorage.getItem('is-touch') === 'true') {
			marginBottomInVw = ratioM(pageData.header_margin_bottom_mobile_in_px) + 'vw'
		} else {
			marginBottomInVw = ratio(pageData.header_margin_bottom_in_px) + 'vw'
		}
		headerEl.current.style.marginBottom = marginBottomInVw
	}, [])

	const sectionsEls = useRef([])
	const sections = slices.map((slice, index) => {
		let section = null
		if (slice.slice_type === 'image_single_centered') {
			section = <ComponentsSections.ImageSingleCentered data={slice} />
		}
		else if (slice.slice_type === 'dual') {
			section = <ComponentsSections.Dual data={slice} />
		}
		else if (slice.slice_type === 'triple_floating_images') {
			section = <ComponentsSections.TripleFloatingImages data={slice} />
		}
		else if (slice.slice_type === 'grid_images') {
			section = <ComponentsSections.GridImages data={slice} />
		}
		else if (slice.slice_type === 'rolling_banner') {
			section = <ComponentsSections.RollingBanner
				inlineSvg={slice.primary.svg_inline_code.text}
				duration={slice.primary.animation_duration_in_seconds}
			/>
		}

		return (
			<div
				className=""
				key={slice.id}
				// style={{ marginBottom: sectionMarginBottomInVw() + 'vw' }}
				data-margin-in-vw={ratio(slice.primary.section_margin_bottom_in_px) + 'vw'}
				data-margin-mobile-in-vw={ratioM(slice.primary.section_margin_bottom_mobile_in_px) + 'vw'}
				ref={ref => sectionsEls.current[index] = ref}
			>
				{section || 'This section doesn\'t exist yet.'}
			</div>
		)
	})

	// Sections margin bottom
	useEffect(() => {
		sectionsEls.current.map(sectionEl => {
			let marginBottomInVw = null
			if (localStorage.getItem('is-touch') === 'true') {
				marginBottomInVw = sectionEl.getAttribute('data-margin-mobile-in-vw')
			} else {
				marginBottomInVw = sectionEl.getAttribute('data-margin-in-vw')
			}
			sectionEl.style.marginBottom = marginBottomInVw
		})
	}, [])

	// Colors change
	useEffect(() => {
		GlobalEventBus.emit('onProjectColorChange', {
			projectData: pageData
		})
	}, [])

	// Left and right arrows to go to prev and next projects (homepage order)
	useEffect(() => {
		const handleKeydown = e => {

			// Go back to home
			if (e.code === 'Escape') {
				navigate('/')
				return
			}

			// Going to prev or next project
			let projectsList = data.prismicHomepage.data.projects
			if (e.code !== 'ArrowRight' && e.code !== 'ArrowLeft') {
				return
			}

			let newIndex = null
			if (e.code === 'ArrowRight') {
				if (projectIndex >= projectsList.length - 1) {
					newIndex = 0
				} else {
					newIndex = projectIndex + 1
				}
			}
			else if (e.code === 'ArrowLeft') {
				if (projectIndex <= 0) {
					newIndex = projectsList.length - 1
				} else {
					newIndex = projectIndex - 1
				}
			}

			if (newIndex !== null) {
				navigate(`/${projectsList[newIndex].project.document.uid}`)
			}
		}
		window.addEventListener('keydown', handleKeydown)
		return () => {
			window.removeEventListener('keydown', handleKeydown)
		}
	})

	return (
		<main className="Project" ref={containerEl}>
			<Seo
				title={pageData.name.text}
				description={pageData.meta_description.text}
			/>
			{pageData.hide_project_from_search_engines === true &&
				<Helmet>
					<meta name="robots" content="noindex"/>
				</Helmet>
			}
			<div className="Project__Header" ref={headerEl}>
				<div className="Project__RollingTitle">
					{pageData.case_title_inline_svg && pageData.case_title_inline_svg.text &&
						<ComponentsSections.RollingBanner inlineSvg={pageData.case_title_inline_svg.text} duration={13} />
					}
				</div>
				{pageData.case_images_enabled === true && pageData.image_case_big.fluid && pageData.image_case_small.fluid &&
					<div className="Project__HeaderImages">
						<img
							className="Project__HeaderImage Project__HeaderImage--Big"
							alt={pageData.image_case_big.alt}
							src={pageData.image_case_big.fluid.src}
							srcSet={pageData.image_case_big.fluid.srcSet}
						/>
						<img
							className="Project__HeaderImage Project__HeaderImage--Small"
							alt={pageData.image_case_small.alt}
							src={pageData.image_case_small.fluid.src}
							srcSet={pageData.image_case_small.fluid.srcSet}
						/>
					</div>
				}
				{pageData.case_header_text &&
					<div className="Project__HeaderTextContainer">
						<div className="Project__HeaderText">
							<RichText render={pageData.case_header_text.richText} />
						</div>
					</div>
				}
				{pageData.case_logo.fluid &&
					<div className="Project__HeaderLogoContainer">
						<img
							className="Project__HeaderLogo"
							alt={pageData.case_logo.alt}
							src={pageData.case_logo.fluid.src}
							srcSet={pageData.case_logo.fluid.srcSet}
						/>
					</div>
				}
			</div>
			<div className="Project__Sections">
				{sections}
			</div>
			<Footer data={data.prismicFooter} duration={10} isDark={pageData.is_footer_dark} />
		</main>
	)
}

export const projectQuery = graphql`
	query ProjectByUid($uid: String) {
		prismicProject(uid: {eq: $uid}) {
			uid
			data {
				color_project_page_logo
				color_project_page_header_text
				color_project_page_header_background
				color_project_page_body_text
      	color_project_page_background
				color_project_page_highlight
				color_transition_background
				hide_project_from_search_engines
				meta_description {
        	text
      	}
				name {
        	text
      	}
				case_title_inline_svg {
        	text
      	}
				case_images_enabled
				image_case_big {
					alt
					fluid {
						src
						srcSet
					}
				}
				image_case_small {
					alt
					fluid {
						src
						srcSet
					}
				}
				case_header_text {
        	richText
    	  }
				case_logo {
					alt
					fluid {
						src
						srcSet
					}
				}
				is_footer_dark
				header_margin_bottom_in_px
				header_margin_bottom_mobile_in_px
				body {
					... on PrismicProjectDataBodyImageSingleCentered {
						id
						slice_type
						primary {
							section_margin_bottom_in_px
							section_margin_bottom_mobile_in_px
							image {
								alt
								fluid {
									src
									srcSet
								}
							}
							video {
								url
							}
							image_columns
							is_full_width
							paragraphs_alignment_with_image
							paragraphs_position_with_image
						}
						items {
							paragraph_content {
								raw
							}
							paragraph_columns
						}
					}
					... on PrismicProjectDataBodyDual {
						id
						slice_type
						primary {
							section_margin_bottom_in_px
							section_margin_bottom_mobile_in_px
							big_image {
								alt
								fluid {
									src
									srcSet
								}
							}
							big_video {
								url
							}
							big_image_side
							big_image_side_width_in_columns
							paragraph_1_content {
								raw
							}
							paragraph_1_margin_left_in_columns
							paragraph_1_width_in_columns
							image {
								alt
								fluid {
									src
									srcSet
								}
							}
							video {
								url
							}
							image_margin_left_in_columns
							image_width_in_columns
							image_vertical_alignment
							other_side_width_in_columns
							paragraph_2_content {
								raw
							}
							paragraph_2_margin_left_in_columns
							paragraph_2_width_in_columns
						}
        	}
					... on PrismicProjectDataBodyTripleFloatingImages {
						id
						slice_type
						primary {
							section_margin_bottom_in_px
							section_margin_bottom_mobile_in_px
							single_image_position
							image_11 {
								alt
								fluid {
									src
									srcSet
								}
							}
							video_1 {
								url
							}
							image_1_legend_width_in_columns
							image_1_legend {
								raw
							}
							image_1_legend_margin_in_columns
							image_1_margin_in_columns
							image_1_width_in_columns
							image_1_margin_bottom_in_px
							image_21 {
								alt
								fluid {
									src
									srcSet
								}
							}
							video_2 {
								url
							}
							image_2_legend {
								raw
							}
							image_2_legend_margin_in_columns
							image_2_legend_width_in_columns
							image_2_margin_in_columns
							image_2_width_in_columns
							image_3_legend_width_in_columns
							image_3_margin_in_columns
							image_3_width_in_columns
							image_3_legend_margin_in_columns
							image_3 {
								alt
								fluid {
									src
									srcSet
								}
							}
							video_3 {
								url
							}
							image_3_legend {
								raw
							}
						}
					}
					... on PrismicProjectDataBodyGridImages {
						id
						slice_type
						primary {
							is_full_width
							section_margin_bottom_in_px
							section_margin_bottom_mobile_in_px
							left_column_width_in_columns
							right_column_width_in_columns
						}
						items {
							column
							image {
								alt
								fluid {
									src
									srcSet
								}
							}
							video {
								url
							}
						}
					}
					... on PrismicProjectDataBodyRollingBanner {
						id
						slice_type
						primary {
							svg_inline_code {
								text
							}
							section_margin_bottom_in_px
							section_margin_bottom_mobile_in_px
							animation_duration_in_seconds
						}
					}
				}
			}
		}
		prismicHomepage {
			data {
				projects {
					project {
						document {
							... on PrismicProject {
								uid
							}
						}
					}
				}
			}
		}
		prismicLoader {
			...Loader
		}
		prismicFooter {
			...Footer
		}
	}
`

export default Project
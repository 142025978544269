import React from 'react'
import { colsWithOuterGutter } from '../../helpers/sizes'
import PreloadImage from '../PreloadImage.js'

const GridImages = ({ data }) => {

	const leftColItems = data.items.filter(item => item.column === 'left')
	const rightColItems = data.items.filter(item => item.column === 'right')

	return (
		<section className={`GridImages ${data.primary.is_full_width ? 'isFullWidth' : ''}`}>
			<div
				className="GridImages__Col GridImages__Col--Left"
				style={{ width: colsWithOuterGutter(data.primary.left_column_width_in_columns) + 'vw' }}
			>
				{leftColItems.map((item, index) => {
					if (item.image.fluid && item.video.url === null) {
						return (
							<PreloadImage
								className="GridImages__Image"
								src={item.image.fluid.src}
								srcSet={item.image.fluid.srcSet}
								alt={item.image.alt}
								key={`grid-image-left-${index}`}
							/>
						)
					}
					else if (item.video.url !== null) {
						return (
							<video
								className="GridImages__Video"
								src={item.video.url}
								muted
								autoPlay={true}
								loop={true}
								playsInline={true}
								key={`grid-image-left-${index}`}
							></video>
						)
					}
				})}
			</div>
			<div
				className="GridImages__Col GridImages__Col--Right"
				style={{ width: colsWithOuterGutter(data.primary.right_column_width_in_columns) + 'vw' }}
			>
				{rightColItems.map((item, index) => {
					if (item.image.fluid && item.video.url === null) {
						return (
							<PreloadImage
								className="GridImages__Image"
								src={item.image.fluid.src}
								srcSet={item.image.fluid.srcSet}
								alt={item.image.alt}
								key={`grid-image-right-${index}`}
							/>
						)
					}
					else if (item.video.url !== null) {
						return (
							<video
								className="GridImages__Video"
								src={item.video.url}
								muted
								autoPlay={true}
								loop={true}
								playsInline={true}
								key={`grid-image-right-${index}`}
							></video>
						)
					}
				})}
			</div>
		</section>
	)
}

export default GridImages
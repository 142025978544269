import React from 'react'
import { RichText } from 'prismic-reactjs'
import { cols, colsWithOuterGutter, ratio } from '../../helpers/sizes'
import PreloadImage from '../PreloadImage.js'

const TripleFloatingImages = ({ data }) => {
	const mainData = data.primary

	return (
		<section
			className="TripleFloatingImages"
			style={{ flexDirection: mainData.single_image_position === 'left' ? 'row-reverse' : '' }}
		>

			<div className="TripleFloatingImages__SideDouble">
				<ImageWrapper
					image={mainData.image_11}
					video={mainData.video_1}
					imageWidth={mainData.image_1_width_in_columns}
					imageMargin={mainData.image_1_margin_in_columns}
					legend={mainData.image_1_legend}
					legendWidth={mainData.image_1_legend_width_in_columns}
					legendMargin={mainData.image_1_legend_margin_in_columns}
					marginSide={mainData.single_image_position === 'left' ? 'marginRight' : 'marginLeft'}
					marginBottom={mainData.image_1_margin_bottom_in_px}
				/>
				<ImageWrapper
					image={mainData.image_21}
					video={mainData.video_2}
					imageWidth={mainData.image_2_width_in_columns}
					imageMargin={mainData.image_2_margin_in_columns}
					legend={mainData.image_2_legend}
					legendWidth={mainData.image_2_legend_width_in_columns}
					legendMargin={mainData.image_2_legend_margin_in_columns}
					marginSide={mainData.single_image_position === 'left' ? 'marginRight' : 'marginLeft'}
				/>
			</div>

			<div className="TripleFloatingImages__SideSingle">
				<ImageWrapper
					image={mainData.image_3}
					video={mainData.video_3}
					imageWidth={mainData.image_3_width_in_columns}
					imageMargin={mainData.image_3_margin_in_columns}
					legend={mainData.image_3_legend}
					legendWidth={mainData.image_3_legend_width_in_columns}
					legendMargin={mainData.image_3_legend_margin_in_columns}
					marginSide={mainData.single_image_position === 'left' ? 'marginLeft' : 'marginRight'}
				/>
			</div>
		</section>
	)
}

export default TripleFloatingImages

const ImageWrapper = ({ image, video, imageWidth, imageMargin, legend, legendWidth, legendMargin, marginSide, marginBottom = 0 }) => {

	if (imageMargin === null) { imageMargin = 0 }
	if (legendMargin === null) { legendMargin = 0 }

	const wrapperStyles = {
		marginBottom: ratio(marginBottom) + 'vw'
	}
	if (imageMargin === 0) {
		wrapperStyles.width = colsWithOuterGutter(imageWidth) + 'vw'
	} else {
		wrapperStyles[marginSide] = colsWithOuterGutter(imageMargin, imageMargin) + 'vw'
		wrapperStyles.width = cols(imageWidth) + 'vw'
	}

	const legendStyles = {}
	if (legendMargin === 0) {
		legendStyles.width = colsWithOuterGutter(legendWidth) + 'vw'
	} else if (legendMargin > 0 && imageMargin === 0) {
		legendStyles.marginLeft = colsWithOuterGutter(legendMargin, legendMargin) + 'vw'
		legendStyles.width = cols(legendWidth) + 'vw'
	} else if (legendMargin > 0 && imageMargin > 0) {
		legendStyles.marginLeft = cols(legendMargin, legendMargin) + 'vw'
		legendStyles.width = cols(legendWidth) + 'vw'
	}

	if (image.fluid || legend.raw.length > 0) {
		return (
			<div
				className="TripleFloatingImages__ImageWrapper"
				style={wrapperStyles}
			>
				{image.fluid && video.url === null &&
					<PreloadImage
						src={image.fluid.src}
						srcSet={image.fluid.srcSet}
						alt={image.alt}
						className="TripleFloatingImages__Image"
					/>
				}
				{video.url !== null &&
					<video
						className="TripleFloatingImages__Video"
						src={video.url}
						muted
						autoPlay={true}
						loop={true}
						playsInline={true}
					></video>
				}
				{legend.raw.length > 0 &&
					<div
						className="TripleFloatingImages__Legend"
						style={legendStyles}
					>
						<RichText render={legend.raw} />
					</div>
				}
			</div>
		)
	} else {
		return <div></div>
	}
}
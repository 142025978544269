import React from 'react'
import { RichText } from 'prismic-reactjs'
import { cols } from '../../helpers/sizes'
import linkResolver from '../../utils/linkResolver'
import PreloadImage from '../PreloadImage.js'

const ImageSingleCentered = ({ data }) => {
	const isFullWidth = data.primary.is_full_width
	const isReversed = data.primary.paragraphs_position_with_image === 'above' ? 'isReversed' : ''

	const paragraphsStyles = {}
	switch (data.primary.paragraphs_alignment_with_image) {
		case 'center':
			paragraphsStyles.justifyContent = 'center'
			break
		case 'right':
			paragraphsStyles.justifyContent = 'flex-end'
			break
	}


	let sectionWidth = 0
	if (isFullWidth) {
		sectionWidth = '100%'
	} else {
		const widthWithCols = cols(data.primary.image_columns)
		sectionWidth = widthWithCols > 100 ? 100 : widthWithCols
	}

	return (
		<section
			className={`ImageSingleCentered ${isReversed} ${isFullWidth ? 'isFullWidth' : ''}`}
			style={{ width: sectionWidth + '%' }}
		>
			{data.primary.image.fluid && data.primary.video.url === null &&
				<PreloadImage
					className="ImageSingleCentered__Image"
					src={data.primary.image.fluid.src}
					srcSet={data.primary.image.fluid.srcSet}
					alt={data.primary.image.alt}
				/>
			}
			{data.primary.video.url !== null &&
				<video
					className="ImageSingleCentered__Video"
					src={data.primary.video.url}
					muted
					autoPlay={true}
					loop={true}
					playsInline={true}
				></video>
			}
			{data.items.length > 0 &&
				<div
					className="ImageSingleCentered__Paragraphs"
					style={paragraphsStyles}
				>
					{data.items.map((item, index) => (
						<div
							className="ImageSingleCentered__Paragraph"
							style={{ width: cols(item.paragraph_columns) + 'vw' }}
							key={`${data.id}-${index}`}
						>
							<RichText render={item.paragraph_content.raw} linkResolver={linkResolver} />
						</div>
					))}
				</div>
			}
		</section>
	)
}

export default ImageSingleCentered